(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
	typeof define === 'function' && define.amd ? define('swiperInit', ['exports'], factory) :
	(global = global || self, factory(global.swiperInit = {}));
}(this, (function (exports) { 'use strict';

	const globalJS = {
	  autoFillNoImage: () => {
	    setTimeout(() => {
	      $("img").each(function () {
	        if ($(this).hasAttr("data-src")) {
	          if ($(this).attr("data-src") === "") {
	            $(this).attr("data-src", "/no-image.png");
	            $(this).attr("src", "/no-image.png");
	            $(this).addClass("noImage");
	          }
	        } else if ($(this).hasAttr("src")) {
	          if ($(this).attr("src") === "") {
	            $(this).attr("src", "/no-image.png");
	            $(this).addClass("noImage");
	          }
	        }
	      });
	    }, 150);
	  },
	  autoClone: () => {
	    var cloneId = $("[id*='autoClone-']");
	    cloneId.each(function (e) {
	      var id = $(this).attr("id").slice(10); // console.log(id);

	      $(this).clone().appendTo("[id*='autoCloneHere-" + id + "']");
	    });
	  },
	  setBackground: () => {
	    var position = ["", "top", "left", "right", "bottom"];
	    jQuery.each(position, function (index, item) {
	      //
	      //
	      $(`[setBackground${item}]`).each(function () {
	        var background = $(this).attr("setBackground" + item);
	        $(this).css({
	          "background-size": "cover",
	          "background-position": `${item} center`,
	          "background-image": `url(${background})`
	        });
	      });
	    });
	  },
	  scrollToTop: () => {
	    $(window).on("scroll", function () {
	      if ($("#main-banner").length || $("#page-banner").length) {
	        if ($(this).scrollTop() >= $("#main-banner").outerHeight() - $("header").outerHeight() || $(this).scrollTop() >= $("#page-banner").outerHeight() - $("header").outerHeight()) {
	          $("#scrollToTop").addClass("active");
	        } else {
	          $("#scrollToTop").removeClass("active");
	        }
	      } else {
	        if ($(this).scrollTop() >= $("header").outerHeight() || $(this).scrollTop() >= $("header").outerHeight()) {
	          $("#scrollToTop").addClass("active");
	        } else {
	          $("#scrollToTop").removeClass("active");
	        }
	      }
	    });
	    $("#scrollToTop").on("click", function () {
	      $("html,body").animate({
	        scrollTop: 0
	      });
	    });
	  },
	  checkListItem: () => {
	    $("main ul").each(function () {
	      $(this).addClass("list-item-added");
	    });
	  },
	  init: () => {
	    globalJS.autoFillNoImage();
	    globalJS.autoClone();
	    globalJS.setBackground();
	    globalJS.scrollToTop();
	    globalJS.checkListItem();
	  }
	};

	exports.globalJS = globalJS;

	Object.defineProperty(exports, '__esModule', { value: true });

})));
